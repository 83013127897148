import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { motion } from 'framer-motion'; 
import '../NotFound/Notfound.css';

const NotFound = () => {
  const navigate = useNavigate();


  const handleRedirect = () => {
    navigate('/'); 
  };

  return (
    <div className="notfound-main">
      <motion.div
        className="notfoundtext"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="sparkle2"></div>
        <h1>404 - Not The One You are Looking For!</h1>
        <div className="unhappy">
          <div className="eye1"></div>
          <div className="eye2"></div>
          <div className="cross11">X</div>
          <div className="cross22">X</div>
          <div className="frown"></div>
        </div>
        <button className="redirect-button" onClick={handleRedirect}>
          Go Back
        </button>
      </motion.div>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import About from '../About/About';
import Experience from '../Experience/Experience';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import Footer from '../Footer/Footer';
import Fun from '../Fun/Fun';
import './HomePage.css';

const HomePage = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const sectionsRef = useRef({}); 


  useEffect(() => {
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      sectionsRef.current[section.id] = section;
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            window.history.pushState(null, '', `#${sectionId}`);
          }
        });
      },
      {
        root: null,
        threshold: 0.3, 
        rootMargin: '0px 0px -20% 0px', 
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  useEffect(() => {
    const handleManualScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const sectionsArray = Object.values(sectionsRef.current);
      let activeSectionId = '';

      for (const section of sectionsArray) {
        const rect = section.getBoundingClientRect();
        if (
          scrollPosition >= rect.top + window.scrollY &&
          scrollPosition <= rect.bottom + window.scrollY
        ) {
          activeSectionId = section.id;
          break;
        }
      }

      if (activeSectionId) {
        window.history.pushState(null, '', `#${activeSectionId}`);
      }
    };

    window.addEventListener('scroll', handleManualScroll);

    return () => {
      window.removeEventListener('scroll', handleManualScroll);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const bottomPosition = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
      setShowScrollToTop(bottomPosition < 50); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Nisal Herath - Home</title>
        <meta
          name="description"
          content="Web site created by Nisal Herath, showcasing projects and portfolio."
        />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          name="twitter:image"
          content="https://x.com/ArtNisal/photo"
        />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "Nisal Herath",
        "url": "https://nisal.lk",
        "sameAs": [
          "https://www.linkedin.com/in/nisalherath",
          "https://github.com/nisalherath",
          "https://www.instagram.com/mooncaque/",
          "https://x.com/ArtNisal"
        ],
        "jobTitle": "Web Developer & Automation Specialist",
        "worksFor": {
          "@type": "Organization",
          "name": "SLIIT"
        },
        "image": "https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364",
        "description": "Nisal Herath is a web developer specializing in React and automation solutions for streamlining tasks."
      }
    `}
        </script>
      </Helmet>

      <Navbar />
      <main>
        <section id="about">
          <About />
        </section>
        <section id="experience">
          <Experience />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="fun">
          <Fun />
        </section>
      </main>
      <Footer />
      {showScrollToTop && (
        <button
          className="scroll-to-top"
          onClick={scrollToTop}
          aria-label="Scroll to top of the page"
        >
          ⬆ Scroll to Top
        </button>
      )}
    </div>
  );
};

export default HomePage;

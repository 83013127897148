import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Experience.css';
import ArtPost from './ArtPost';
import AutomationPost from './AutomationPost';
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Correct named import
import 'react-tooltip/dist/react-tooltip.css'; // Import Tooltip styles
import browse from '../Assets/browse.svg';
import discord from '../Assets/discord.svg';

import pic1 from '../Assets/Elephant.webp';
import pic2 from '../Assets/Christmas 2021.webp';
import pic3 from '../Assets/Camel.webp';
import pic4 from '../Assets/Christmas 2022.webp';
import pic5 from '../Assets/GOT.webp';
import pic6 from '../Assets/Cricket.webp';
import pic7 from '../Assets/Milky.webp';
import pic8 from '../Assets/Control.webp';
import pic9 from '../Assets/Icey night.webp';

import bot1 from '../Assets/bot1.webp';
import bot2 from '../Assets/bot2.webp';
import bot3 from '../Assets/bot3.webp';
import bot4 from '../Assets/bot4.webp';
import bot5 from '../Assets/bot5.webp';

import python from '../Assets/py.svg';
import replyimg from '../Assets/7020CD61-6B89-4CF5-988D-7D9F1BA0F616.webp';

const images = [
  { className: 'pic1', src: pic1 },
  { className: 'pic2', src: pic2 },
  { className: 'pic3', src: pic3 },
  { className: 'pic4', src: pic4 },
  { className: 'pic5', src: pic5 },
  { className: 'pic6', src: pic6 },
  { className: 'pic7', src: pic7 },
  { className: 'pic8', src: pic8 },
  { className: 'pic9', src: pic9 },
];

const automationImages = [
  { className: 'bot1', src: bot1 },
  { className: 'bot2', src: bot2 },
  { className: 'bot3', src: bot3 },
  { className: 'bot4', src: bot4 },
  { className: 'bot5', src: bot5 },
];

const Experience = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexAutomation, setCurrentIndexAutomation] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showReply, setShowReply] = useState(false);
  const [showImage, setShowImage] = useState(false);


  const messages = [
    { text: '/IP', reply: 'IP Address:📫\n 103.21.166.251 \n Location:📍\n Colombo \n ZIP Code:✉️ \n 00100 ' },
    { text: '/Wallpapers', reply: 'Here is a Wallpaper!🌅', replyimg: replyimg },
    { text: '/Gamedeals', reply: 'No Gamedeals for this Week!☹️' },
  ];




  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessage((prev) => {
        const nextMessage = prev + 1;
        return nextMessage <= messages.length ? nextMessage : 1; // Cycle to 1 if it exceeds the number of messages
      });
      setShowReply(false);
      setTimeout(() => setShowReply(true), 1500);
    }, 3000);
  
    return () => clearInterval(messageInterval);
  }, [messages.length]);
  
  useEffect(() => {
    if (currentMessage > 0 && showReply) {
      setShowImage(false);
      const imageTimeout = setTimeout(() => setShowImage(true), 500);
      return () => clearTimeout(imageTimeout);
    }
  }, [currentMessage, showReply]);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, 3000);

    const automationInterval = setInterval(() => {
      setCurrentIndexAutomation((prev) => (prev + 1) % automationImages.length);
    }, 3000);

    return () => {
      clearInterval(imageInterval);
      clearInterval(automationInterval);
    };
  }, []);

  // Preload all images
  useEffect(() => {
    const preloadImages = [...images, ...automationImages].map((image) => {
      const img = new Image();
      img.src = image.src;
      return img;
    });
    // Preload images by accessing their src
    preloadImages.forEach((img) => img.onload = () => {});
  }, []);

  const variants = {
    enter: { opacity: 0, y: 100, position: 'absolute' },
    center: { opacity: 1, y: 0, position: 'absolute' },
    exit: { opacity: 0, y: -100, position: 'absolute' },
  };

  const justPostedVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: 'easeOut' } },
  };

 const everydayphone = {
    alt: "iPhone"
  };


  return (
    <div id="experience" className="experience-section">


      <motion.div
        className="expdiv"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >




        <div className="art">


  <div className="exp-flex-column2">
  <h1 className="exptext">Digital Art</h1>
          <div className="arttext">
            <h1 className="artheader">Digital Art</h1>

            {/* Text truncation logic */}
            <motion.div
              className="arttext-content"
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.8 }}
            >
              With over 4 years of experience in vector art since 2020, I specialize in designing custom icons, logos, portraits, character designs, and NFT art. My creative process blends attention to detail and a passion for digital design to bring visually striking and unique concepts to life.
            </motion.div>
  </div>


  <div className="arttext">
            <h1 className="artheader">Socials</h1>
            <motion.div
              className="arttext-content"
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.8 }}
            >
              You can Find my Artworks here.


          <div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://web.facebook.com/mooncaque', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Facebook
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://www.instagram.com/mooncaque/', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Instagram
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://www.behance.net/nisalherath', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Behance
            </button>
          </div>
            </motion.div>
  </div>
  </div>

  <div className="exp-flex-column1">

<ArtPost
  images={images}
  currentIndex={currentIndex}
  variants={variants}
  justPostedVariants={justPostedVariants}
/>
</div>
</div>
      </motion.div>





{/* Automation */}

<motion.div
      className="expdiv"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.1 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >


      <div className="automation">
        

      <div className="exp-flex-column1">

<AutomationPost
  automationImages={automationImages}
  currentIndexAutomation={currentIndexAutomation}
  variants={variants}
  justPostedVariants={justPostedVariants}
/>
</div>

      <div className="exp-flex-column2">

      <h1 className="exptext">Automation</h1>
        <div className="automationtext">
          <div className="pythonimg">
            <img src={python} alt="pythonimg" />
          </div>
          <div className="flower"></div>
          <h1 className="automationheader">Automation</h1>
          <motion.div
            className="automationtext-content"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >
            I specialize in creating Python-based bots and automation solutions that simplify processes and increase productivity. I focus on developing web applications with React and automating tasks such as social media posts and other repetitive activities to save time and improve efficiency. My approach combines technical expertise with a passion for optimizing workflows through automation.
          </motion.div>
        </div>




        <div className="automationtext">
          <h1 className="automationheader">Socials</h1>
          <motion.div
            className="automationtext-content"
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }} 
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8 }}
          >
            You can find this Bot on these socials.
          
            <div className="button-container">
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://web.facebook.com/RandomSinhalaWord', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Facebook
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://www.instagram.com/sinhala333/', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Instagram
            </button>
            <button className="githubbtn githubbtn1" onClick={() => window.open('https://x.com/sinhala333', '_blank')}>
              <img src={browse} className="githubimg" alt="GitHub" />
              On Twitter
            </button>
          </div>
          </motion.div>

        </div>
        </div>

      </div>
    </motion.div>















{/* Everyday Life */}
<motion.div
        className="expdiv"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >


<div className="everyday">
  
<div className="exp-flex-column2">
<h1 className="exptext">Everyday Life</h1>
<div className="everydaytext">
    <h1 className="everydayheader">Everyday Life</h1>

    {/* Text truncation logic */}
    <motion.div
        className="everydaytext-content"
        initial={{ opacity: 0, y: 50 }} 
        whileInView={{ opacity: 1, y: 0 }} 
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
I develop utility bots that automate everyday tasks to improve user experience, using APIs to streamline processes. One bot, for example, streamlines Discord interactions by automating features like fetching images, providing game deals, delivering quotes, and running speed tests. My focus is on creating practical, easy-to-use solutions that save time and enhance productivity.

</motion.div>
</div>



  <div className="everydaytext">
    <h1 className="everydayheader">On Discord</h1>

    {/* Text truncation logic */}
    <motion.div
        className="everydaytext-content"
        initial={{ opacity: 0, y: 50 }} 
        whileInView={{ opacity: 1, y: 0 }} 
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
            You can try out one of these bots and their functionalities for Free on Discord.
          
          <div className="button-container">
          <button className="githubbtn githubbtn1" onClick={() => window.open('https://discord.com/oauth2/authorize?client_id=1288790078826479646', '_blank')}>
              <img src={discord} className="githubimg" alt="GitHub" />
              Try it on Discord
            </button>
        </div>
</motion.div>
</div>
  </div>

  <div className="exp-flex-column1">
        <div
    className="everydayphone"
    alt="iphone8"
    data-tooltip-id="everyday-tooltip" 
    data-tooltip-content={everydayphone.alt} 
  >

<ReactTooltip
           id="everyday-tooltip"
           effect="solid"                // Appearance effect
           place="top"                   // Tooltip position
           multiline={true}              // Enable multiline content
           className="custom-tooltip"    // Apply custom CSS class
           clickable={true}              // Tooltip remains open on click
           delayShow={100}               // Delay before tooltip appears
           delayHide={100}               // Delay before tooltip hides
           positionStrategy="fixed"      // Fixed position for consistency
           resizeObserver={true}         // Dynamically adjusts position on resize
           closeOnScroll={true}          // Hides tooltip on scroll  // Hides the tooltip if the user scrolls the page
           arrowColor="rgba(31, 22, 83, 0.7)"
          />
    <div className="phonebtn1"></div>
    <div className="phonespeaker1"></div>
    <div className="phonecamera1"></div>


    <AnimatePresence>
      {/* Send Message Divs */}
      {currentMessage > 0 && (
        <motion.div
          className={`sendermsg smsg${currentMessage}`} // Dynamically add class
          key={`sendermsg-${currentMessage}`}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <p className={`smsg${currentMessage}`}>{messages[currentMessage - 1].text}</p>
        </motion.div>
      )}

      {/* Receive Message Divs */}
      {currentMessage > 0 && showReply && (
        <motion.div
          className={`recievermsg rmsg${currentMessage}`} // Dynamically add class
          key={`receivermsg-${currentMessage}`}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          {/* Render Reply Text */}
          <p className={`rmsg${currentMessage}`}>{messages[currentMessage - 1].reply}</p>

          {/* Conditionally Render Reply Image after delay */}
          {showImage && messages[currentMessage - 1].replyimg && (
            <motion.img
              src={messages[currentMessage - 1].replyimg}
              alt="Reply Visual"
              className={`replyimg rimg${currentMessage}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>



  </div>
  </div>




</div>
      </motion.div>


    </div>
  );
};

export default Experience;

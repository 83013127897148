import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import './About.css';

import astronautImage from '../Assets/Astronaut.svg';
import starImage from '../Assets/star.svg';
import sparkleImage from '../Assets/sparkle.svg';
import planetImage from '../Assets/Planet.svg';
import swordImage from '../Assets/sword.svg';
import cardImage from '../Assets/card.svg';

const images = [
  { className: 'astronaut', src: astronautImage, alt: 'Astronaut in space' },
  { className: 'star-1', src: starImage, alt: 'Star' },
  { className: 'star-2', src: starImage, alt: 'Star' },
  { className: 'star-3', src: starImage, alt: 'Star' },
  { className: 'sparkle-1', src: sparkleImage, alt: 'Sparkle effect' },
  { className: 'sparkle-2', src: sparkleImage, alt: 'Sparkle effect' },
  { className: 'sparkle-3', src: sparkleImage, alt: 'Sparkle effect' },
  { className: 'planet', src: planetImage, alt: 'Planet' },
  { className: 'sword', src: swordImage, alt: 'Sword' },
  { className: 'bg-card', src: cardImage, alt: 'Background card design' },
];

const preloadImages = (imageArray) => {
  imageArray.forEach(({ src }) => {
    const img = new Image();
    img.src = src;
  });
};

const About = () => {
  const text = "Welcome ";
  const [displayText, setDisplayText] = useState(""); 
  const [cursorVisible, setCursorVisible] = useState(true);
  const [index, setIndex] = useState(0);
  const [expanded, setExpanded] = useState(false); 
  const [showReadMore, setShowReadMore] = useState(false); 
  const textRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  // Preload images on component mount
  useEffect(() => {
    preloadImages(images);
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };
    
    checkScreenSize(); 
    window.addEventListener('resize', checkScreenSize); 
    return () => window.removeEventListener('resize', checkScreenSize); 
  }, []);

  useEffect(() => {
    if (isMobile) {
      setDisplayText("Welcome");
    } else if (index < text.length) {
      const interval = setInterval(() => {
        setDisplayText((prev) => prev + text[index]); 
        setIndex((prevIndex) => prevIndex + 1); 
      }, 150);

      return () => clearInterval(interval);
    } else {
      const resetInterval = setInterval(() => {
        setDisplayText(""); 
        setIndex(0); 
      }, 2000); 

      return () => clearInterval(resetInterval); 
    }
  }, [index, isMobile]); 

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      if (index < text.length) {
        setCursorVisible((prev) => !prev); 
      }
    }, 500); 

    return () => clearInterval(cursorInterval); 
  }, [index]); 

  const checkTextHeight = useCallback(() => {
    if (textRef.current) {
      const contentHeight = textRef.current.scrollHeight;
      setShowReadMore(contentHeight > 450); 
    }
  }, []);

  useEffect(() => {
    checkTextHeight(); 
    window.addEventListener('resize', checkTextHeight); 
    return () => window.removeEventListener('resize', checkTextHeight);
  }, [checkTextHeight]);

  const handleReadMoreClick = () => {
    setExpanded(true);
  };

  return (
    <div className="about-main">
      <motion.section
        id="about"
        className="about-section"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="abttextdiv">
          <h1 className="welcometext">
            {displayText}
            <span className={`cursor ${cursorVisible ? "blink" : ""}`}>|</span>
          </h1>

          <div className="abouttext">
            <div className="sparkle"></div>
            <motion.div
              className="abouttext-content"
              ref={textRef}
              initial={{ maxHeight: '450px' }}
              animate={{
                maxHeight: expanded ? '1000px' : '450px',
              }}
              style={{ overflow: 'hidden' }}
              layout
            >
              I'm Nisal Herath, a third-year Computer Science student and an Automation Dev passionate about building bots and automation solutions. Alongside my technical pursuits, I create digital artworks. Currently pursuing my degree, I focus on developing web apps with React and creating Python bots that automate tasks like social media posts. I'm always looking for ways to use technology to improve productivity. 
            </motion.div>

            {showReadMore && !expanded && (
              <button className="read-more" onClick={handleReadMoreClick}>
                Read More
              </button>
            )}
          </div>
        </div>

        <div className="profilecard">
          <div className="card">
            <p className="title">Reach For IT</p>

            {images.map((image, index) => (
              <img
                key={index}
                className={image.className}
                src={image.src}
                alt={image.alt}
              />
            ))}
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default About;

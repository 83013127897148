import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Experience.css';
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Correct named import
import 'react-tooltip/dist/react-tooltip.css'; // Import Tooltip styles

const automationpc = {
  alt: "Desktop"
};

const AutomationPost = ({ automationImages, currentIndexAutomation, variants, justPostedVariants }) => {
  return (
    <div
    className="automationpc"
    alt="iphone8"
    data-tooltip-id="automationpc-tooltip" 
    data-tooltip-content={automationpc.alt} 
  >

<ReactTooltip
           id="automationpc-tooltip"
           effect="solid"                // Appearance effect
           place="top"                   // Tooltip position
           multiline={true}              // Enable multiline content
           className="custom-tooltip"    // Apply custom CSS class
           clickable={true}              // Tooltip remains open on click
           delayShow={100}               // Delay before tooltip appears
           delayHide={100}               // Delay before tooltip hides
           positionStrategy="fixed"      // Fixed position for consistency
           resizeObserver={true}         // Dynamically adjusts position on resize
           closeOnScroll={true}          // Hides tooltip on scroll  // Hides the tooltip if the user scrolls the page
           arrowColor="rgba(31, 22, 83, 0.7)"
          />
      <AnimatePresence>
        <motion.div
          className="justposted2"
          key={`justposted-${currentIndexAutomation}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={justPostedVariants}
        >
          <p>Sinhala Bot Just Posted+</p>
        </motion.div>

        <motion.div
          key={currentIndexAutomation}
          className="automationpost post"
          initial="enter"
          animate="center"
          exit="exit"
          variants={variants}
          transition={{ duration: 1 }}
        >
          <div className="profpic">

          </div>
          <h1 className="profname2">Sinhala Bot</h1>
          <div className="postbarrier2">
            <img
              src={automationImages[currentIndexAutomation].src}
              alt={`Bot ${currentIndexAutomation + 1}`}
              className={automationImages[currentIndexAutomation].className}
              loading="lazy"
            />
          </div>
        </motion.div>

        <motion.div
          key={`prev-${currentIndexAutomation}`}
          className="automationpostpost post"
          initial="center"
          animate="exit"
          variants={variants}
          transition={{ duration: 1 }}
        >
          <div className="postbarrier2">
            <img
              src={automationImages[(currentIndexAutomation + automationImages.length - 1) % automationImages.length].src}
              alt={`Bot ${currentIndexAutomation}`}
              className={automationImages[(currentIndexAutomation + automationImages.length - 1) % automationImages.length].className}
              loading="lazy"
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AutomationPost;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./CSStips.css";
import codepenLogo from '../Assets/codepen.svg';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CSStips = () => {
  const [timeString, setTimeString] = useState("88:88:88");
  const [glowClass, setGlowClass] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);
  const [isRetroMode, setIsRetroMode] = useState(false);
  const [modeChanging, setModeChanging] = useState(false);
  const navigate = useNavigate(); 
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bottomPosition = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
      setShowScrollToTop(bottomPosition < 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const updateTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();


    hours = hours % 12;
    hours = hours ? hours : 12; 

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!timerRunning) {
        setTimeString("88:88:88"); 
      }
    }, 1000); 

    return () => clearInterval(intervalId); 
  }, [timerRunning]);

  const handleButtonClick = () => {
    if (timerRunning) {
      return; 
    }

    setTimerRunning(true);
    setGlowClass("glow"); 


    setTimeString(updateTime());


    setTimeout(() => {
      setGlowClass(""); 
      setTimeString("88:88:88"); 
      setTimerRunning(false); 
    }, 2000); 
  };


  useEffect(() => {
    const retroModeEnabled = localStorage.getItem('retroModeEnabled') === 'true';
    if (retroModeEnabled) {
      setIsRetroMode(true);
      enableRetroMode();
    }
  }, []);


  const enableRetroMode = () => {
    document.body.classList.add('retro-mode');
    localStorage.setItem('retroModeEnabled', 'true');
    setModeChanging(true);

    const elementsToRotate = ['back1', 'back2', 'back3'];
    elementsToRotate.forEach((id) => {
      const element = document.getElementById(id);
      if (element) element.classList.add('rotated');
    });

    setTimeout(() => setModeChanging(false), 100);
  };


  const disableRetroMode = () => {
    document.body.classList.remove('retro-mode');
    localStorage.setItem('retroModeEnabled', 'false');
    setModeChanging(true);

    const elementsToRotate = ['back1', 'back2', 'back3'];
    elementsToRotate.forEach((id) => {
      const element = document.getElementById(id);
      if (element) element.classList.add('reverse');
    });

    setTimeout(() => {
      elementsToRotate.forEach((id) => {
        const element = document.getElementById(id);
        if (element) element.classList.remove('rotated', 'reverse');
      });
      setModeChanging(false);
    }, 700);
  };


  const handleToggleChange = () => {
    if (!modeChanging) {
      if (isRetroMode) {
        disableRetroMode();
      } else {
        enableRetroMode();
      }
      setIsRetroMode(!isRetroMode);
    }
  };


  const handleBackHomeClick = () => {
    const lastSection = localStorage.getItem('lastSection');
    if (lastSection) {

      navigate("/", { state: { scrollTo: lastSection } });
    } else {

      navigate('/');
    }
  };
  


  return (
    <section id="tips-section" className="tips-main">
        <Helmet>
        <title>Nisal Herath - Fun w/CSS </title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div className="titlediv">
      <motion.div
        className="css-title"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      > 
      <h1>Fun w/CSS</h1>
      <button className="backhome" onClick={handleBackHomeClick}>
        <div className="arrowhead">
          <div className="arrowline1"></div>
          <div className="arrowline2"></div>
          <div className="arrowmainline"></div>
        </div>
        
         Back Home 
      </button>
      </motion.div>
      <div className="Flake Flake1">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake2">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake3">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake4">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake5">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>
      </div>
      <motion.div
        className="tips-component"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >

        <div className="tips-items">

        <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
  <div className="tags">
    <div className="html-tag">HTML</div>
    <div className="css-tag">CSS</div>
    </div>
<div className="tip-item tip1">
    <div className="Mountains Card">
      <p className="christmasText">Merry Christmas</p>

      {/* Mountains */}
      <div className="Mountain MountainLeft1"></div>
      <div className="Mountain MountainLeft2"></div>
      <div className="Mountain MountainLeft3"></div>
      <div className="Mountain MountainMiddle"></div>
      <div className="Mountain MountainRight1"></div>
      <div className="Mountain MountainRight2"></div>
      <div className="Mountain MountainRight3"></div>

      {/* Snowflakes */}
      <div className="Flake Flake1">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake2">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake3">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake4">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>

      <div className="Flake Flake5">
        <div className="stroke stroke-1"></div>
        <div className="stroke stroke-2"></div>
        <div className="stroke stroke-3"></div>
        <div className="stroke stroke-4"></div>
      </div>
    </div>
 </div>
 <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/GRVLzmM', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
</motion.div>    



<motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
            <div className="js-tag">JS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/dyEXxdj', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip2">
          <div className={`clock-container ${glowClass}`}>
                <div className="clock-container2">
                  <div className={`clock-outline ${glowClass}`}>
                    <div className="clock-outline2"></div>
                    <div className="actual-clock">
                      <div className="clock-text">{timeString}</div>
                      <button
                        className="clock-button"
                        onClick={handleButtonClick}
                        disabled={timerRunning}
                      >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </motion.div>


    <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
    <div className="tags">
    <div className="html-tag">HTML</div>
    <div className="css-tag">CSS</div>
    </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/MWRoYxv', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip3">
   <div className="loading-dotys">
      <div className="doty-1"></div>
      <div className="doty-2"></div>
      <div className="doty-3"></div>
      <div className="doty-4"></div>
    </div>
          </div>
          </motion.div>





    <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
            <div className="js-tag">JS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/bGJBebb', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip4">
          <div className="retro-mode-toggle">
      <input
        type="checkbox"
        id="retro-mode-color-switch"
        checked={isRetroMode}
        onChange={handleToggleChange}
      />
      <label className="color-switch" htmlFor="retro-mode-color-switch">
        <div className="retro">
          <div className="ball"></div>
          <p className="on-text">
            <em>ON</em>
          </p>
          <p className="off-text">OFF</p>
        </div>
      </label>
    </div>
          </div>
          </motion.div>


          <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/mdZewpr', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip5">
            <div className="loading"></div>
          </div>
          </motion.div>


          <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/xbKGrdy', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip6">
          <div className="fun-itemys">
          <div className="itemy itemy1">
            <p className="itemytitle">FUN</p>
          </div>
          </div>
          </div>
          </motion.div>






          <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/emONRBy', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip7">
            <div className="phone">
              <div className="iphonebtn"></div>
              <div className="iphonespeaker"></div>
              <div className="iphonecamera"></div>
            </div>
          </div>
          </motion.div>





          <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
          <div className="tags">
            <div className="html-tag">HTML</div>
            <div className="css-tag">CSS</div>
          </div>
          <button className="codepenbtn" onClick={() => window.open('https://codepen.io/Mooncaque/pen/YPKXQWb', '_blank')}> <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code</button>
          <div className="tip-item tip8">
            <div className="browser-tab">
              <div className="browserclose"><div className="line1"></div><div className="line2"></div></div>
              <div className="browsertask"><div className="square"></div></div>
              <div className="browserminimize"><div className="dashy"></div></div>
              <div className="browser-inside">404</div>
            </div>
          </div>
          </motion.div>






          <motion.div
        className="tipss"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
  <button
    className="codepenbtn"
    onClick={() => window.open('', '_blank')}
    disabled={true} // Disable the button
  >
    <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />Get the Code
  </button>
  <div className="tip-item tip9">
    <p>More Coming Soon!</p>
  </div>
</motion.div>

        </div>
      </motion.div>
      <Footer />
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={scrollToTop} aria-label="Scroll to top of the page">
        ⬆ Scroll to Top
        </button>
      )}
    </section>
  );
};

export default CSStips;

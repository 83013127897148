import React, { useState, useEffect, useCallback } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0); 


  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };


  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };


  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;


    if (currentScrollY === 0) {
      setIsNavbarVisible(true);
    } else {

      if (currentScrollY < lastScrollY) {
        setIsNavbarVisible(true); 
      } else {
        setIsNavbarVisible(false);
      }
    }


    setIsScrolled(currentScrollY > window.innerHeight / 2);

    setLastScrollY(currentScrollY);
  }, [lastScrollY]); 


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });


    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]); 


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className={`navbar ${isScrolled ? 'shrink' : ''} ${!isNavbarVisible ? 'hidden' : ''}`}>
      <div className="navbar-container">
        {/* Logo with onClick to scroll to the top */}
        <h1 className="logo" onClick={scrollToTop}>
          Nisal Herath
        </h1>
        <div className="menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? '✖' : '☰'}
        </div>
        <ul className={`navbar-items ${isMobileMenuOpen ? 'active' : ''}`}>
  <li>
    <ScrollLink
      to="about"
      href="https://nisal.lk/#about"
      smooth={true}
      duration={500}
      onClick={closeMobileMenu}
    >
      About Me
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="experience"
      href="https://nisal.lk/#experience"
      smooth={true}
      duration={500}
      onClick={closeMobileMenu}
    >
      Experience
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="skills"
      href="https://nisal.lk/#skills"
      smooth={true}
      duration={500}
      onClick={closeMobileMenu}
    >
      Skills
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="projects"
      href="https://nisal.lk/#projects"
      smooth={true}
      duration={500}
      onClick={closeMobileMenu}
    >
      Projects
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="fun"
      href="https://nisal.lk/#fun"
      smooth={true}
      duration={500}
      onClick={closeMobileMenu}
    >
      Fun
    </ScrollLink>
  </li>
</ul>
      </div>
    </nav>
  );
};

export default Navbar;

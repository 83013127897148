import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Fun.css";

const Fun = () => {
  const navigate = useNavigate();

  const handleCSSTipsClick = () => {

    window.scrollTo(0, 0);

    navigate("/css");
  };

  const handleNerdcakeClick = () => {

    window.scrollTo(0, 0);

    navigate("/nerdcake");
  };

  return (
    <section id="fun" className="fun-main">
      <motion.div
        className="fun-component"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="projectsheader2">Having Fun With</h1>
        <motion.div
          className="fun-items"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="item item1" onClick={handleCSSTipsClick}>
            <p className="itemtitle">CSS Tips</p>
          </div>
          <div className="item item2" onClick={handleNerdcakeClick}>
            <p className="itemtitle">Nerdcake</p>
          </div>
          <div className="item item3" onClick={() => window.open("https://minigamexyz.netlify.app/", "_blank")}>
            <p className="itemtitle">Game</p>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Fun;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Experience.css';
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Correct named import
import 'react-tooltip/dist/react-tooltip.css'; // Import Tooltip styles

const artphone = {
  alt: "iPhone"
};
const ArtPost = ({ images, currentIndex, variants, justPostedVariants }) => {
  return (
    <div
    className="artphone"
    alt="iphone8"
    data-tooltip-id="artphone-tooltip" 
    data-tooltip-content={artphone.alt} 
  >

<ReactTooltip
           id="artphone-tooltip"
           effect="solid"                // Appearance effect
           place="top"                   // Tooltip position
           multiline={true}              // Enable multiline content
           className="custom-tooltip"    // Apply custom CSS class
           clickable={true}              // Tooltip remains open on click
           delayShow={100}               // Delay before tooltip appears
           delayHide={100}               // Delay before tooltip hides
           positionStrategy="fixed"      // Fixed position for consistency
           resizeObserver={true}         // Dynamically adjusts position on resize
           closeOnScroll={true}          // Hides tooltip on scroll  // Hides the tooltip if the user scrolls the page
           arrowColor="rgba(31, 22, 83, 0.7)"
          />
      <div className="phonebtn1"></div>
      <div className="phonespeaker1"></div>
      <div className="phonecamera1"></div>

      <AnimatePresence>
        <motion.div
          className="justposted"
          key={`justposted-${currentIndex}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={justPostedVariants}
        >
          <p>Nisal Just Posted an Artwork +</p>
        </motion.div>

        <motion.div
          key={currentIndex}
          className="artpost post"
          initial="enter"
          animate="center"
          exit="exit"
          variants={variants}
          transition={{ duration: 1 }}
        >
          <div className="profpic"></div>
          <h1 className="profname">Nisal Herath</h1>
          <div className="postbarrier">
            <img
              src={images[currentIndex].src}
              alt={`Art ${currentIndex + 1}`}
              className={images[currentIndex].className}
              loading="lazy"
            />
          </div>
        </motion.div>

        <motion.div
          key={`prev-${currentIndex}`}
          className="artpost post"
          initial="center"
          animate="exit"
          variants={variants}
          transition={{ duration: 1 }}
        >
          <div className="profpic"></div>
          <div className="postbarrier">
            <img
              src={images[(currentIndex + images.length - 1) % images.length].src}
              alt={`Art ${currentIndex}`}
              className={images[(currentIndex + images.length - 1) % images.length].className}
              loading="lazy"
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ArtPost;
